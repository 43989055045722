import { useId, useState } from 'react'
import { FeedbackButton } from '../FeedbackButton/FeedbackButton'
import { Wrapper } from './Wrapper/Wrapper'
import If from '~/core/ui/If'

interface Props {
  label: string | React.ReactNode
  collapsible?: boolean
  collapsed?: boolean
  children: React.ReactNode
}

export const SidebarGroup: React.FC<Props> = ({
  label,
  collapsed = false,
  collapsible = true,
  children,
}) => {
  const [isGroupCollapsed, setIsGroupCollapsed] = useState(collapsed)
  const id = useId()
  return (
    <div className="flex flex-col space-y-1 py-1">
      <Wrapper
        id={id}
        label={label}
        collapsible={collapsible}
        isGroupCollapsed={isGroupCollapsed}
        setIsGroupCollapsed={setIsGroupCollapsed}
      />
      <If condition={collapsible ? !isGroupCollapsed : true}>
        <div
          id={id}
          className="
              animate-in fade-in slide-in-from-top-2
              flex flex-col space-y-1.5 duration-500 ease-out"
        >
          {children}
          <FeedbackButton />
        </div>
      </If>
    </div>
  )
}

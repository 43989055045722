import React, { useContext } from 'react'
import Button from '../../../Button'
import { FeedbackPopupContainer } from '~/components/FeedbackPopup'
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import SidebarContext from '~/lib/contexts/sidebar'

export const FeedbackButton: React.FC = () => {
  const { collapsed } = useContext(SidebarContext)
  return (
    <FeedbackPopupContainer>
      <Button variant="outline">
        <ChatBubbleLeftRightIcon className="h-6" />
        {collapsed === false ? <span className="ml-1">Feedback</span> : null}
      </Button>
    </FeedbackPopupContainer>
  )
}

import { useQuery } from '@tanstack/react-query'
import useSupabase from '~/core/hooks/use-supabase'
import { getOrganizationsByUserId } from '~/lib/organizations/database/queries'

/**
 * @description Hook to fetch the user's organizations
 */
function useUserOrganizationsQuery(
  userId: string,
  params: {
    enabled?: boolean
    refetchOnMount?: boolean
    refetchOnWindowFocus?: boolean
  },
) {
  const client = useSupabase()
  const queryKey = ['organizations', userId]

  const queryFn = async () => {
    return getOrganizationsByUserId(client, userId).then(
      result => result.data,
    )
  }

  return useQuery({
    queryKey,
    queryFn,
    ...params,
  })
}

export default useUserOrganizationsQuery

import { useContext, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

import { SelectArrow } from '@radix-ui/react-select'
import { useNavigate } from '@remix-run/react'
import classNames from 'clsx'

import type Organization from '~/lib/organizations/types/organization'
import useUserOrganizationsQuery from '~/lib/organizations/hooks/use-user-organizations-query'

import {
  Select,
  SelectAction,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '~/core/ui/Select'

import If from '~/core/ui/If'
import Spinner from '~/core/ui/Spinner'
import { Avatar, AvatarFallback } from '~/core/ui/Avatar'

import UserSessionContext from '~/core/session/contexts/user-session'
import type MembershipRole from '~/lib/organizations/types/membership-role'
import useCurrentOrganization from '~/lib/organizations/hooks/use-current-organization'
import { Skeleton } from '~/core/ui/skeleton'

const OrganizationsSelector: React.FC<{
  displayName: boolean
}> = ({ displayName }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const organization = useCurrentOrganization()
  const { userSession } = useContext(UserSessionContext)

  const userId = userSession?.data?.id as string
  const selectedOrganizationId = organization?.id.toString() ?? ''

  const { data, isLoading } = useUserOrganizationsQuery(userId, {
    enabled: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const userOrganizations = data ?? []

  if (isLoading) {
    return (
      <div className="px-4 max-w-48 ">
        <div className="flex items-center space-x-4">
          <Skeleton className="size-6 rounded-full" />
          <div className="space-y-2">
            <Skeleton className="h-6 w-[100px]" />
          </div>
        </div>
      </div>
    )
  }

  if (!userOrganizations || userOrganizations.length === 0) {
    return null
  }

  if (userOrganizations.length === 1) {
    return (
      <div className="px-4">
        <OrganizationItem organization={organization} />
      </div>
    )
  }

  return (
    <>
      <Select
        open={isOpen}
        value={selectedOrganizationId}
        onValueChange={(organization) => {
          const path = getPath(organization)

          navigate(path, {
            replace: true,
          })
        }}
        onOpenChange={setIsOpen}
      >
        <SelectTrigger asChild>
          <div
            role="button"
            className={classNames(
              `text-sm flex lg:text-base group hover:bg-gray-50 cursor-pointer border-none dark:hover:bg-dark-900/50 dark:hover:text-white outline outline-1 outline-gray-100 dark:outline-dark-900`,
              'px-4',
              {
                'justify-between max-h-12': displayName,
                'rounded-full mx-auto': !displayName,
              },
            )}
            data-cy="organization-selector"
          >
            <OrganizationItem
              organization={organization}
              displayName={displayName}
            />

            <If condition={displayName}>
              <EllipsisVerticalIcon className="block h-5 text-gray-500/70 group-hover:text-gray-500" />
            </If>

            <span hidden>
              <SelectValue />
            </span>
          </div>
        </SelectTrigger>

        <SelectContent position="popper" className="max-h-[60vh]">
          <SelectArrow />

          <SelectGroup>
            <SelectLabel>Your Organizations</SelectLabel>

            <SelectSeparator />

            <OrganizationsOptions
              organizations={data ?? []}
              organizationId={organization?.id}
            />

            <If condition={isLoading}>
              <SelectAction className="flex flex-row items-center space-x-2 truncate">
                <Spinner className="!h-4 !w-4" />

                <span>
                  <Trans i18nKey="common:loading" />
                </span>
              </SelectAction>
            </If>
          </SelectGroup>

          {/* <SelectSeparator />

          <SelectGroup>
            <SelectAction
              data-cy={'create-organization-button'}
              className={'flex flex-row items-center space-x-2 truncate'}
              onClick={() => setIsCreateOrganizationModalOpen(true)}
            >
              <PlusCircleIcon className={'h-5'} />

              <span>
                <Trans
                  i18nKey={'organization:createOrganizationDropdownLabel'}
                />
              </span>
            </SelectAction>
          </SelectGroup> */}
        </SelectContent>
      </Select>

      {/* <CreateOrganizationModal
        onCreate={() => {
          setIsCreateOrganizationModalOpen(false);
          setIsOpen(false);
          refetch();
        }}
        isOpen={isCreateOrganizationModalOpen}
        setIsOpen={setIsCreateOrganizationModalOpen}
      /> */}
    </>
  )
}

function OrganizationsOptions(
  props: React.PropsWithChildren<{
    organizations: Array<{
      organization: Organization
      role: MembershipRole
    }>
    organizationId: Maybe<number>
  }>,
) {
  const organizations = useMemo(() => {
    return [...props.organizations].sort((prev, next) => {
      if (prev.organization.id === props.organizationId) {
        return -1
      }

      return prev.organization.id - next.organization.id
    })
  }, [props.organizations, props.organizationId])

  return (
    <>
      {organizations.map(({ organization }) => {
        return (
          <SelectItem
            data-cy="organization-selector-item"
            value={organization.id.toString()}
            key={organization.id}
          >
            <OrganizationItem organization={organization} />
          </SelectItem>
        )
      })}
    </>
  )
}

export function OrganizationItem({
  organization,
  displayName = true,
}: {
  organization: Maybe<Organization>
  displayName?: boolean
}) {
  if (!organization) {
    return null
  }

  const { logoURL, name } = organization

  return (
    <div
      className={classNames(`flex max-w-[12rem] items-center space-x-2.5`, {
        'w-full': displayName,
      })}
    >
      <If
        condition={logoURL}
        fallback={(
          <FallbackOrganizationLogo
            className={displayName ? '' : 'mx-auto'}
            name={organization.name}
          />
        )}
      >
        <img
          decoding="async"
          loading="lazy"
          alt={`${name} Logo`}
          className="size-6 object-contain"
          src={logoURL as string}
        />
      </If>

      <If condition={displayName}>
        <span className="w-auto truncate text-sm font-medium hover:text-clip">
          {name}
        </span>
      </If>
    </div>
  )
}

export default OrganizationsSelector

function getPath(organizationId: string) {
  return `/resources/organizations/${organizationId}/set`
}

function FallbackOrganizationLogo(
  props: React.PropsWithChildren<{
    name: string
    className?: string
  }>,
) {
  const initials = (props.name ?? '')
    .split(' ')
    .reduce((acc, word) => {
      return acc + word[0]
    }, '')
    .slice(0, 1)

  return (
    <Avatar className={classNames('!w-6 !h-6', props.className)}>
      <AvatarFallback>{initials}</AvatarFallback>
    </Avatar>
  )
}

import { Trans } from 'react-i18next'

import { useRouteLoaderData } from '@remix-run/react'
import type { NavigationItem } from '../navigation.config'
import NAVIGATION_CONFIG from '../navigation.config'
import type { loader } from '~/root'
import useCurrentOrganization from '~/lib/organizations/hooks/use-current-organization'
import { SidebarGroup } from '~/core/ui/Sidebar/components/SidebarGroup/SidebarGroup'
import { SidebarItem } from '~/core/ui/Sidebar/components/SidebarItem/SidebarItem'
import { SidebarDivider } from '~/core/ui/Sidebar/components/SidebarDivider/SidebarDivider'

export function shouldShowNavItem(item: NavigationItem, organization?: number, env?: string) {
  if ((item as any)?.development) {
    if (env === 'prod') {
      return false
    }
  }
  // allows us to show items to specific organizations in production
  if ((item as any)?.orgs) {
    if (env === 'prod' && !(item as any)?.orgs.includes(organization)) {
      return false
    }
  }
  return true
}

function AppSidebarNavigation() {
  const data = useRouteLoaderData<typeof loader>('root')
  const ENV = data?.ENV
  const organization = useCurrentOrganization()

  const items = NAVIGATION_CONFIG.items.filter((item) => {
    return shouldShowNavItem(item, organization?.id, ENV?.STAGE)
  })

  return (
    <>
      {items.map((item, index) => {
        if ('divider' in item) {
          return <SidebarDivider key={index} />
        }

        if ('children' in item) {
          return (
            <SidebarGroup
              key={item.label}
              label={<Trans i18nKey={item.label} defaults={item.label} />}
              collapsible={item.collapsible}
              collapsed={item.collapsed}
            >
              {item.children.map((child) => {
                return (
                  <SidebarItem
                    key={child.path}
                    end={child.end}
                    path={child.path}
                    Icon={child.Icon}
                  >
                    <Trans i18nKey={child.label} defaults={child.label} />
                  </SidebarItem>
                )
              })}
            </SidebarGroup>
          )
        }

        return (
          <SidebarItem
            key={item.path}
            end={item.end}
            path={item.path}
            Icon={item.Icon}
          >
            <Trans i18nKey={item.label} defaults={item.label} />
          </SidebarItem>
        )
      })}
    </>
  )
}

export default AppSidebarNavigation

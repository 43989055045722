import { useContext } from 'react'
import classNames from 'clsx'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Title } from '../Title/Title'
import SidebarContext from '~/lib/contexts/sidebar'

interface Props {
  id: string
  label: string | React.ReactNode
  collapsible: boolean
  isGroupCollapsed: boolean
  setIsGroupCollapsed: (collapsed: boolean) => void
}

export const Wrapper: React.FC<Props> = ({
  id,
  label,
  collapsible,
  isGroupCollapsed,
  setIsGroupCollapsed,
}) => {
  const { collapsed: sidebarCollapsed } = useContext(SidebarContext)

  const containerClasses = classNames(
    'group flex items-center justify-between px-container space-x-2.5',
    { 'py-2.5': !sidebarCollapsed },
  )

  const handleToggleCollapse = () => {
    setIsGroupCollapsed(!isGroupCollapsed)
  }

  return collapsible
    ? (
        <button
          aria-expanded={!isGroupCollapsed}
          aria-controls={id}
          onClick={handleToggleCollapse}
          className={containerClasses}
        >
          <Title>{label}</Title>
          <ChevronDownIcon
            className={classNames('transition duration-300 h-3', {
              'rotate-180': !isGroupCollapsed,
            })}
          />
        </button>
      )
    : (
        <div className={containerClasses}>
          <Title>{label}</Title>
        </div>
      )
}

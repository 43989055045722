import { useContext } from 'react'
import SidebarContext from '~/lib/contexts/sidebar'

export const Title: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { collapsed: sidebarCollapsed } = useContext(SidebarContext)
  if (sidebarCollapsed) {
    return null
  }
  return (
    <span className="
            text-xs font-semibold uppercase
            text-gray-500 group-hover:text-gray-600
            dark:text-gray-400 dark:group-hover:text-gray-300"
    >
      {children}
    </span>
  )
}

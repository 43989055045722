import {
  ChartBarIcon,
  CodeBracketSquareIcon,
  Squares2X2Icon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { AudioLinesIcon, BrainIcon, UserRoundSearchIcon } from 'lucide-react'
import configuration from '~/configuration'

interface Divider {
  divider: true
}

export interface NavigationItemLink {
  label: string
  path: string
  Icon: (props: { className: string }) => JSX.Element
  end?: boolean
  development?: boolean
  orgs?: number[]
}

interface NavigationGroup {
  label: string
  collapsible?: boolean
  collapsed?: boolean
  children: NavigationItemLink[]
}

export type NavigationItem = NavigationItemLink | NavigationGroup | Divider

interface NavigationConfig {
  items: NavigationItem[]
}

const NAVIGATION_CONFIG: NavigationConfig = {
  items: [
    {
      label: 'common:dashboardTabLabel',
      path: configuration.paths.appHome,
      Icon: ({ className }: { className: string }) => {
        return <Squares2X2Icon className={className} />
      },
      end: true,
    },
    {
      label: 'Transcribe',
      path: '/transcribe',
      Icon: ({ className }: { className: string }) => {
        return <AudioLinesIcon className={className} />
      },
      end: false,
    },
    {
      label: 'common:coding:tabLabel',
      path: '/coding',
      Icon: ({ className }: { className: string }) => {
        return <CodeBracketSquareIcon className={className} />
      },
      end: false,
    },
    {
      label: 'AI Researcher',
      path: '/research',
      Icon: ({ className }: { className: string }) => {
        return <BrainIcon className={className} strokeWidth={1.5} />
      },
      end: false,
    },
    {
      label: 'Augmented Panel',
      path: '/augmentedpanel',
      orgs: [9],
      Icon: ({ className }: { className: string }) => {
        return <UserRoundSearchIcon className={className} strokeWidth={1.5} />
      },
      end: true,
    },
    {
      label: 'Quant',
      orgs: [9],
      path: '/quant',
      Icon: ({ className }: { className: string }) => {
        return <ChartBarIcon className={className} />
      },
      end: true,
    },

    {
      label: 'common:settingsTabLabel',
      collapsible: false,
      children: [
        {
          label: 'common:profileSettingsTabLabel',
          path: configuration.paths.settings.profile,
          Icon: ({ className }: { className: string }) => {
            return <UserIcon className={className} />
          },
        },
        {
          label: 'common:organizationSettingsTabLabel',
          path: configuration.paths.settings.organization,
          Icon: ({ className }: { className: string }) => {
            return <UserGroupIcon className={className} />
          },
        },
        // {
        //   label: 'common:subscriptionSettingsTabLabel',
        //   path: configuration.paths.settings.subscription,
        //   Icon: ({ className }: { className: string }) => {
        //     return <CreditCardIcon className={className} />;
        //   },
        // },
      ],
    },
  ],
}

export default NAVIGATION_CONFIG

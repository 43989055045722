import { PageHeader } from '~/core/ui/Page'
import MobileAppNavigation from '~/components/MobileNavigation'

const AppHeader: React.FCC<{
  title: string | React.ReactNode
  description?: string | React.ReactNode
}> = ({ children, title, description }) => {
  return (
    <PageHeader
      title={title}
      description={description}
      mobileNavigation={<MobileAppNavigation />}
    >
      {children}
    </PageHeader>
  )
}

export default AppHeader
